:root {
  /* --light-color: rgb(210, 200, 191); */
  --light-color: #eae7dc;
  /* --main-color: #07244a; */
  --main-color: #5e5d5a;
  --accent-color: #e85a4f;
  --accent-light-color: #e98074;
  --active-color: #200a07;
  --paper-color: #bcbcbc;
  --pencil-color: #393939;
}

.App {
  animation: fadeIn 0.4s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  /* background-color: rgb(211, 217, 222); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto', sans-serif;
  padding: 4.65em 8em 3em;
  color: var(--main-color);
}

.App-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto', sans-serif;
  padding: 4em 2em 2em;
  color: var(--main-color);
}

.App-link {
  color: #61dafb;
}

.navbar {
  position: fixed;
  /* Make the navbar fixed at the top of the page */
  top: 0;
  width: 100%;
  background-color: var(--light-color);
  /* Or any color you prefer */
  z-index: 100;
  /* Ensure the navbar is above other content */
  justify-content: center;
  transition: box-shadow 0.12s ease-in-out;
}

.navbar.show {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.3);
  /* Optional: Add a shadow for a bit of depth */
}

.nav-links-container {
  display: flex;
  /* Use flexbox for layout */
  justify-content: flex-end;
  /* Align links to the right */
  padding-right: 24px;
  /* Add some padding */
}

.nav-links {
  position: relative; /* Add this line */
  display: inline-block; /* Add this line to ensure button is only as wide as its content */
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: none;
  border: none;
  font: inherit;
  text-decoration: none;
  color: var(--main-color);
  font-size: 20px;
}

.nav-links:hover {
  color: var(--main-color);
}

.nav-links::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%; /* Width is relative to the button, which is only as wide as the text */
  height: 2px;
  bottom: 8px;
  left: 0;
  background-color: var(--accent-color);
  transform: scaleX(0);
  transform-origin: center; /* This will make the scale effect expand from the center */
  transition: transform 0.275s ease;
}

.nav-links:hover::before {
  transform: scaleX(1);
}


button:disabled {
  cursor: default;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  font-family: 'Roboto', sans-serif;
  /* Or any other font you want to use */
  font-size: 18px;
  color: var(--main-color);
  /* Dark text */
  background: transparent;
  /* Background that matches its surrounding */
  border: 2px solid var(--main-color);
  /* Dark border */
  border-radius: 6px;
  /* Rounded corners */
  transition: background 0.25s ease, color 0.25s ease;
  /* Transition for a smooth color change */
  text-decoration: none;
  /* Removes underline if the button is a link */
}

.nav-button {
  margin-left: 9px;
  /* Add some margin between links */
  margin-right: 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  color: var(--light-color);
  /* Or any color you prefer */
  font-size: 20px;
  /* Set a font size */
  display: inline-block;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 13px;
  padding-right: 13px;
  background: var(--accent-light-color);
  /* Background that matches its surrounding */
  border: 2px solid var(--accent-light-color);
  /* Dark border */
  border-radius: 8px;
  /* Rounded corners */
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  /* Transition for a smooth color change */
  text-decoration: none;
  /* Removes underline if the button is a link */
}

.nav-button:hover {
  background: var(--light-color);
  /* Dark background */
  color: var(--accent-color)
    /* Light text */
}

.nav-button:active {
  background: var(--accent-color);
  /* Slightly lighter background */
  color: var(--light-color);
  border: 2px solid var(--accent-color);
  /* Light text */
}

.button:hover {
  background: var(--main-color);
  /* Dark background */
  color: var(--light-color)
    /* Light text */
}

.button:active {
  background: var(--active-color);
  /* Slightly lighter background */
  color: var(--light-color);
  /* Light text */
}

.scrollTop {
  position: fixed;
  right: 4%;
  bottom: 6%;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: var(--light-color);
  /* Light background */
  border: 2px solid var(--accent-light-color);
  color: var(--accent-light-color);
  /* Dark border */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.175);
  z-index: 1000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  /* Start as hidden */
  transition: opacity 0.135s ease-in-out, background 0.25s ease, color 0.25s ease;
  /* Transition for a smooth color change */
}

.scrollTop:hover {
  background: var(--accent-light-color);
  /* Dark background */
  color: var(--light-color);
  border: 2px solid var(--accent-light-color);
  /* Light text */
}

.scrollTop:active {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
  /* Slightly lighter background */
  color: var(--light-color);
  /* Light text */
}

.scrollTop.show {
  opacity: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.big-header {
  font-size: 5.0em;
  font-weight: lighter;
  margin-bottom: .38em;
}

.Footer {
  height: 50px;
  /* Define a height for your footer */
  background-color: var(--active-color);
  /* Background color for your footer */
  color: var(--light-color);
  /* Font color for your footer */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  /* Pushes the footer to the bottom */
}

.button-inline {
  margin-top: 2em;
  margin-left: 0.375em;
}

body {
  background-color: var(--light-color);
}

.Subtitle-project {
  color: var(--light-color);
  font-size: 1.8em;
  font-weight: 350;
  margin-top: 0em;
  padding-left: 0.16em;
  margin-bottom: 0.78em;
  text-align: left;
}

.Subtitle-dark {
  color: var(--main-color);
  font-size: 1.8em;
  font-weight: 350;
  margin-top: 0em;
  padding-left: 0.16em;
  margin-bottom: 0.78em;
  text-align: left;
}

.Subtitle {
  color: var(--accent-color);
  font-size: 1.8em;
  font-weight: 350;
  margin-top: 0em;
  padding-left: 0.16em;
  margin-bottom: 0.78em;
  text-align: left;
}


.Body-text {
  padding-left: .4em;
  text-align: left;
  line-height: 1.35;
  font-size: 0.875em;
}

.Body-text-light {
  color: var(--light-color);
}

.Caption {
  font-size: 0.6em;
  padding-left: 0.4em;
  line-height: 1.15;
  text-align: left;
  font-style: italic;
}

.Contact-line {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  margin-left: 3px;
}

.Contact-line a {
  color: var(--main-color);
  text-decoration: none;
  margin-left: 0.2em;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Contact-line p {
  color: var(--main-color);
  text-decoration: none;
  margin-left: 0.2em;
  margin-top: 24px;
  margin-bottom: 24px;
}

.video-background {
  position: relative;
  height: 45em;
  /* Set your height here */
  overflow: hidden;
  /* Prevents video spilling out */
  width: 100%;
}

.video-banner {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  min-height: 100%;
  object-fit: cover;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 6em;
  margin-left: 6em;
  margin-top: 2em;
  margin-bottom: 2em;
}

.video-container-mobile {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.video {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.blur-img-background {
  position: relative;
  height: 35em;
  /* Set your height here */
  overflow: hidden;
  /* Prevents video spilling out */
  width: 100%;
}

.blur-img {
  position: absolute;
  z-index: 1;
  filter: blur(4px);
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  min-height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}

.blur-img::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* this will set the ::before pseudo-element behind the text */
}


.blur-img-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto', sans-serif;
  padding: 4em 2em 2em;
  color: var(--main-color);
  line-height: 1.85;
  font-weight: 400;
  position: absolute;
  z-index: 2;
}



.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 45%;
  color: var(--light-color);
  backdrop-filter: blur(15px);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.025) 100%);
  overflow: hidden;
  /* To ensure the pseudo-element doesn't extend beyond the div */
}

.blur-overlay-right {
  right: 0;
  left: auto;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.025) 100%);
}

.blur-overlay-content {
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto', sans-serif;
  line-height: 1.85;
  font-weight: 400;
  padding-left: 5em;
  padding-right: 4em;
  padding-top: 4em;
  padding-bottom: 2em;
}

.blur-overlay-content-right {
  padding-left: 4em;
  padding-right: 5em;
}

.blur-overlay-button {
  display: inline-block;
  padding: 12px 24px;
  font-family: 'Roboto', sans-serif;
  /* Or any other font you want to use */
  font-size: 18px;
  color: var(--light-color);
  /* Dark text */
  background: transparent;
  /* Background that matches its surrounding */
  border: 2px solid var(--light-color);
  /* Dark border */
  border-radius: 6px;
  /* Rounded corners */
  transition: background 0.25s ease, color 0.25s ease;
  /* Transition for a smooth color change */
  text-decoration: none;
  /* Removes underline if the button is a link */
  margin-top: 2em;
  margin-left: 0.375em;
}

.blur-overlay-button:hover {
  background: var(--light-color);
  /* Dark background */
  color: var(--main-color)
    /* Light text */
}

.blur-overlay-button:active {
  background: var(--light-color);
  /* Slightly lighter background */
  color: var(--active-color);
  /* Light text */
}

.blur-img-button {
  color: var(--active-color);
  border-color: var(--active-color);
}

.blur-img-button:hover {
  color: var(--light-color);
  background-color: var(--active-color);
}

.blur-img-button:active {
  color: var(--light-color);
  background-color: var(--main-color);
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; /* Number of lines you want to display */
  overflow: hidden;
}

/*  */

/* 
  <p>Hello <em>world</em>!</p>
  <p id="root">Hello <em>world</em>!</p>
  <p class="underlined">Hello <em>world</em>!</p>
  <p class="epc-style-class-name button underlined yellow">Hello <em>world</em>!</p>
 */

/* 
p {
  
}

p:nth-child(2) {
  color: red;
}


#root {
  color: red;
}

.underlined {
  text-decoration: underline;
} */